<template>
  <div class="pl-8 pr-8">
    <div class="ml-3 mr-3">
      <v-row>
        <v-col class="pl-0 pr-0 text-left" cols="12">
          <!-- <h1 class="linguo__title-text mb-7">Linguo</h1> -->
          <v-layout justify-end>
            <img
              width="200"
              style="margin-left: -10px"
              class="mb-4"
              src="../../assets/img/Linguo.png"
            />
          </v-layout>
          <p class="linguo__subtitle mb-4">
            Mejore la redacción, ortografía y gramática de sus textos
          </p>
        </v-col>
      </v-row>
    </div>

    <v-row>
      <v-col class="my-0 py-0" cols="6">
        <p class="linguo__text mb-0 text-left">Escriba su texto</p>
      </v-col>
      <v-col class="my-0 py-0" cols="6">
        <p class="linguo__text mb-0 text-left">
          Acá esta su resultado

          <v-btn
            v-if="linguoId != null"
            fab
            small
            :color="color.btnLike"
            class="mx-1"
            elevation="0"
            @click="like()"
          >
            <img :src="color.srcLike" />
          </v-btn>

          <v-btn
            v-if="linguoId != null"
            small
            fab
            :color="color.btnDis"
            class="mx-1"
            elevation="0"
            @click="modal.disLike = true"
          >
            <img :src="color.srcDis" />
          </v-btn>
        </p>
      </v-col>
      <v-col class="my-0 py-0" cols="6">
        <v-card class="rounded-lg" outlined>
          <vue-editor
            :editor-toolbar="customToolbar"
            ref="editor"
            v-model="html"
          ></vue-editor>
        </v-card>

        <v-btn @click="send()" rounded block color="#466be3" class="mt-4" dark>
          <strong> mejorar </strong>
        </v-btn>
      </v-col>
      <v-col class="my-0 py-0" cols="6">
        <v-card outlined>
          <vue-editor
            :editor-toolbar="customToolbar"
            ref="editor"
            v-model="html2"
          ></vue-editor>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog v-model="modal.disLike" max-width="500">
      <v-card class="px-5 py-5" outlined style="border-radius: 15px">
        <v-card-title class="text-center">
          ¿No le gusto la respuesta?
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-textarea
              :rules="[(v) => !!v || 'Este campo es obligatorio']"
              v-model="feedback"
              label="ingrese aquí su retroalimentación"
            ></v-textarea>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="6">
              <v-btn
                rounded
                block
                outlined
                color="#3957B9"
                @click="modal.disLike = false"
              >
                Cancelar
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn :loading="loading" rounded block color="#3957B9" @click="disLike()" dark>
                Enviar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <Alert
      :open="alert.open"
      :text="alert.txt"
      :title="alert.title"
      :type="alert.type"
      :redirect="alert.redirect"
      @close="alert.open = false"
    >
    </Alert>
    <v-overlay :value="loading">
      <v-progress-circular :size="120" color="#3957B9" indeterminate>
        Procesando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import { VueEditor } from "vue2-editor";
import Alert from "../../components/Alert.vue";
export default {
  components: {
    VueEditor,
    Alert,
  },
  data() {
    return {
      origin: window.location.origin,
      linguoId: null,
      alert: {
        txt: "",
        title: "",
        type: "",
        redirect: "",
        open: false,
      },
      customToolbar: [
        [{ header: [false, 1, 2, 3, 4, 5, 6] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
        [{ color: [] }, { background: [] }],
      ],
      html: "",
      html2: "",
      token: {
        prompt_tokens: "",
        completion_tokens: "",
      },
      loading: false,
      token1: null,
      name: "",
      infoToken: {
        document: "",
      },
      color: {
        btnLike: "#0000",
        btnDis: "#0000",
        srcLike: origin + "/icon/like.svg",
        srcDis: origin + "/icon/dislike.svg",
      },
      modal: {
        disLike: false,
      },
      feedback: "",
    };
  },
  created() {
    this.getToken();
  },
  methods: {
    clearLike() {
      this.color.btnLike = "#0000";
      this.color.srcLike = this.origin + "/icon/like.svg";
    },
    clearDisLike() {
      this.color.btnDis = "#0000";
      this.color.srcDis = this.origin + "/icon/dislike.svg";
    },
    like() {
      this.clearDisLike();
      if (this.color.btnLike != "#466BE3") {
        var data = {
          like: 1,
          linguo_id: this.linguoId,
        };
        this.loading = true;
        Api.Auth()
          .likeOrDislike(this.token1, data)
          .then((resP) => {
            this.color.btnLike = "#466BE3";
            this.color.srcLike = this.origin + "/icon/like-while.svg";
            this.loading = false;
            this.alert.open = true;
            this.alert.title = "Excelente";
            this.alert.txt = resP.data.message;
            this.alert.type = "success";
            console.log("se registró el dato");
          })
          .catch((error) => {
            this.loading = false;
            console.log(error);
          });
      }
    },
    disLike() {
      this.clearLike();
      this.modal.disLike = true;
      if (this.color.btnDis != "#466BE3") {
        var data = {
          like: 0,
          feedback: this.feedback,
          linguo_id: this.linguoId,
        };
        if (this.$refs.form.validate()) {
          this.loading = true;
          Api.Auth()
            .likeOrDislike(this.token1, data)
            .then((resP) => {
              this.loading = false;
              console.log("se registró el dato");
              this.color.btnDis = "#466BE3";
              this.color.srcDis = this.origin + "/icon/dislike-while.svg";
              this.modal.disLike = false;
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = resP.data.message;
              this.alert.type = "success";
            })
            .catch((error) => {
              this.loading = false;

              console.log(error);
            });
        }
      }
    },
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");

      this.token1 = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
        // console.log(JSON.parse(atob(div[1])));
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    send() {
      this.loading = true;
      var data = {
        //instruction: "corregir ortografía, redacción, ser empatico",
        prompt: this.html,
      };
      Api.Auth()
        .spelling(data)
        .then((resp) => resp.json())
        .then((res) => {
          this.loading = false;
          var clear = res.choices[0].message.content.replaceAll('[/crg]', '');
          this.html2 = clear.replaceAll('[crg]', '');
          this.token = res.usage;
          var dataReport = {
            completion_tokens: res.usage.completion_tokens,
            prompt_tokens: res.usage.prompt_tokens,
            total_tokens: res.usage.total_tokens,
            original_message: this.html,
            new_message: res.choices[0].message.content,
          };
          Api.Auth()
            .saveSpelling(this.token1, dataReport)
            .then((resP) => {
              console.log("se registró el dato");
              this.linguoId = resP.data.data;
              this.alert.open = true;
              this.alert.title = "Excelente";
              this.alert.txt = resP.data.message;
              this.alert.type = "success";
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style>
@import "./../../assets/css/main.less";
.linguo__title-text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.linguo__subtitle {
  font-family: "RobotoLight";
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #353535;
}

.linguo__text {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 59px;
  color: #353535;
}
</style>
